import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import { IconButton, TextField } from '@mui/material'
import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';
import { PrimaryButton } from '../CustomMUI/CustomButtons';

function Footer() {
  return (
    <footer className='footer'>
      <div className="px-5">
        <div className="d-flex justify-content-between flex-wrap">
          <div className='mb-4'>
            <h3>Company</h3>
            <ul className="list-group">
              <li><Link to="/products">Products</Link></li>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
              <li><Link to="/press">Press</Link></li>
              <li><Link to="/gallery">Gallery</Link></li>
            </ul>
          </div>
          <div className='mb-4'>
            <h3>Our Solutions</h3>
            <ul className="list-group">
              <li><Link to="/">Ride & Earn</Link></li>
              <li><Link to="/fleet">Fleet Management</Link></li>
            </ul>
          </div>
          <div className='mb-4'>
            <h3>Get Support</h3>
            <ul className="list-group">
              <li><a href={`tel:(+94) 77 564 8650`}>
                (+94) 77 564 8650
              </a></li>
              <li><a href={`mailto:info@slingmobility.com`}>
                info@slingmobility.com
              </a></li>
            </ul>
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <h5>Get the latest updates</h5>
          <div className='d-flex justify-content-evenly'>
            <IconButton
              href='https://www.facebook.com/slingmobility'
              target='blank' size='large' style={{ color: '#fff' }}>
              <Facebook fontSize='large' style={{ color: '#fff' }} />
            </IconButton>
            <IconButton
              href='https://www.instagram.com/slingmobility/'
              target='blank' size='large' style={{ color: '#fff' }}
            >
              <Instagram fontSize='large' style={{ color: '#fff' }} />
            </IconButton>
            <IconButton
              href='https://www.linkedin.com/company/slingmobility/'
              target='blank' size='large' style={{ color: '#fff' }}
            >
              <LinkedIn fontSize='large' style={{ color: '#fff' }} />
            </IconButton>
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-xl-9 mb-3'>
            <TextField
              size='small'
              label="Email"
              variant="outlined"
              fullWidth required
              autoComplete='email'
              type='email'
              InputProps={{
                style: {
                  color: "white",
                  border: "1px solid white",
                }
              }}
              InputLabelProps={{
                style: {
                  color: "white"
                }
              }}
            />
          </div>
          <div className='col-xl-3 mb-3'>
            <PrimaryButton fullWidth className='red-btn'>
              Subscribe
            </PrimaryButton>
          </div>
        </div>
        <div className='d-flex justify-content-between flex-wrap'>
          <div className='d-flex'>
            <Link to="/privacy">Privacy Policy </Link>
            <small>&nbsp;|&nbsp;</small>
            <Link to="/terms">Terms and conditions</Link>
          </div>
          <small>Copyright © 2024 Sling Mobility</small>
        </div>
      </div>
    </footer>
  )
}

export default Footer