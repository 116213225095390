import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Bike from './pages/Bike/Bike';
import Subscriptions from './pages/Subscriptions/Subscriptions';
import Home from './pages/Home/Home';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Buy from './pages/Buy/Buy';
import Contact from './pages/Contact';
import Press from './pages/Press/Press';
import AboutUs from './pages/Aboutus/Aboutus';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Gallery from './pages/Gallery/Gallery';
import FleetManagement from './pages/FleetManagement/FleetManagement';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PaymentReturn from './pages/PaymentReturn';


function BuyWithParams() {
  const { initialStep } = useParams();
  return <Buy initialStep={Number(initialStep)} />;
}

function App() {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  AOS.init({ duration: 1000, once: false })
  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <Router>
          <Navbar />
          <ToastContainer theme="colored" />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/products/:id' element={<Bike />} />
            <Route path='/subscriptions' element={<Subscriptions />} />
            <Route path='/privacy' element={<PrivacyPolicy />} />
            <Route path='/buy' element={<Buy />} />
            <Route path="/buy/:initialStep" element={<BuyWithParams />}  />
            <Route path='/fleet' element={<FleetManagement />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/press' element={<Press />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/payment-return' element={<PaymentReturn />} />
            <Route path='/marketingQr' element={<Home />} />
          </Routes>
          <Footer />
        </Router>
      </div>
    </ThemeProvider>
  );
}
export default App;