import { List, ListItem, ListItemIcon } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from 'react'
import { SecondaryButton } from '../../components/CustomMUI/CustomButtons';
import './FleetManagement.css'
import Carousel from 'react-multi-carousel';

function FleetManagement() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  };

  const packageTypes = [{
    title: "Basic",
    subtitle: "For organizations who require logistics vehicles to complete their deliveries.",
    price: "0.75",
    features: [
      "Scooter",
      "Battery as a Service (BaaS)",
      "Minimum OQ - 300"
    ]
  },
  {
    title: "Standard",
    subtitle: "For organizations who require to implement a new fleet to manage deliveries.",
    price: "1",
    features: [
      "Scooter",
      "Rider",
      "Battery as a Service (BaaS)",
      "Minimum OQ - 200"
    ]
  }]

  return (
    <div className='container text-center my-5'>
      <h1 className='pt-5 page-title'>Fleet Management</h1>
      <p>"Unlock unparalleled efficiency and reliability with our fleet operator services."</p>
      <Carousel className='fleet-carousel my-5' responsive={responsive} >
        <img src='/images/fleet_dashboard.png' className='img-fluid' alt='fleet' data-aos="fade-up" />
      </Carousel>
      <div className='fleet-grid'>
        <div className='mt-auto mb-auto text-start' data-aos="fade-right">
          <h2 className='page-title'>Sling Fleet for Enterprise</h2>
          <p className='mb-5'>
            Our easy-to-use and detailed fleet management system makes it possible for you to have complete control over your fleet of EVs. From tracking your fleet or vehicle through your device to managing and tracking orders and battery analytics reports, all the data related to your fleet is collected through our advanced Vehicle Intelligence Module (VIM) and Battery Management System (BMS) and visualized and analysed for your benefit.
          </p>
        </div>
        {packageTypes.map((packageType, index) =>
          <div
            key={index}
            className='card d-flex flex-column justify-content-between'
            data-aos="fade-right"
            data-aos-delay={(index+1) * 200}
          >
            <div>
              <h2 className={index === 1 ? 'text-orange' : 'text-light-blue'}>{packageType.title}</h2>
              <small>{packageType.subtitle}</small>
              <h3 className='mt-2'>${packageType.price}</h3>
              <List>
                {packageType.features.map((feature, index) =>
                  <ListItem key={index} disableGutters>
                    <ListItemIcon>
                      <CheckCircleIcon color='primary' />
                    </ListItemIcon>
                    {feature}
                  </ListItem>
                )}
              </List>
            </div>
            <SecondaryButton>
              <a href='https://fleet.slingmobility.com' target="_blank" rel="noreferrer">
                Get Started
              </a>
            </SecondaryButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default FleetManagement