import React, { useEffect, useState } from 'react'
import './Home.css'
import Hero from './Hero';
import FirebaseServices from '../../Services/FirebaseServices';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import HeroBikes from './HeroBikes';
import Services from './Services';
import Subscriptions from '../Subscriptions/Subscriptions';
import { useLocation } from 'react-router-dom';

function Home() {
  const firebase = new FirebaseServices();
  const [bikes, setBikes] = useState([] as any)
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation();

  useEffect(() => {
    getBikes()

    if (location.pathname === '/marketingQr') {
      firebase.updateQRCount().then(() => {
        console.log("Updated qr count")
      }).catch((error) => {
        console.log("Failed to update qr count")
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getBikes() {
    setIsLoading(true)
    await firebase.getAllBikes().then((bikeDetails: any) => {
      setBikes(bikeDetails)
      setIsLoading(false)
    }).catch((error) => {
      toast.error(error)
    });
  }

  return (
    <div>
      <Hero />
      {isLoading ? (
        <div className='text-center p-5 vh-100'>
          <CircularProgress />
        </div>
      ) : (
        <HeroBikes products={bikes} />
      )}
      <Subscriptions />
      <Services />
    </div>
  )
}

export default Home